import values from 'lodash/values';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import includes from 'lodash/includes';
import some from 'lodash/some';
import transform from 'lodash/transform';

import { routesMap } from '../routes-map';
import { ROLES, isProd } from './core/constants';
import { withACL } from './core/with-acl';

const { User, Service, CountryManager, SuperAdmin, Partner, Marketing, Sales } =
  ROLES;

const ALL_VISITORS = values(ROLES);

const ConnectedACLRules = {
  ConfigurationPage: [Service, SuperAdmin],
  MyProfilePage: [Service, SuperAdmin],
  CountriesPage: [Service, SuperAdmin],
  PosPage: [Service, CountryManager, SuperAdmin],
  UsersPage: [Service, SuperAdmin],
  OffersPage: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Partner,
    Marketing,
    Sales,
  ],
  OffersSearchAllPosPage: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Partner,
    Marketing,
    Sales,
  ],
  OffersPageAddingOffers: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Marketing,
  ],
  OffersPageAddingOffersFromFile: isProd
    ? []
    : [User, Service, CountryManager, SuperAdmin, Partner, Marketing],
  ResetPricesPage: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Partner,
    Marketing,
  ],
  PublishOffersBulkPage: [
    User,
    Service,
    CountryManager,
    SuperAdmin,
    Partner,
    Marketing,
  ],
  ManagePortalPage: [SuperAdmin, Marketing],
  SEOPage: [Service, CountryManager, SuperAdmin, Marketing],
  UploadPhotos: [User, Service, CountryManager, SuperAdmin, Marketing],
  TransactionsPage: [Service, SuperAdmin],
  TranslationsPage: [Service, SuperAdmin],
  LabelsPage: [Service, SuperAdmin, Marketing, CountryManager],
  PartnerCompaniesPage: isProd ? [] : [Service, SuperAdmin],
  VehicleGroupsPage: isProd ? [] : [Service, SuperAdmin],
  PromotionParametersPage: isProd ? [] : [Service, SuperAdmin],
  LogsPage: [Service, SuperAdmin],
  ReportsPage: [Service, SuperAdmin, CountryManager],
};

export const routing = [
  {
    path: '/login',
    exact: true,
    mainMenu: false,
    component: routesMap.LoginPage,
    allowRoles: ALL_VISITORS,
  },
  {
    path: '/external-login',
    exact: true,
    mainMenu: false,
    component: routesMap.ExternalLoginPage,
    allowRoles: ALL_VISITORS,
  },
  {
    path: '/sso',
    exact: true,
    component: routesMap.SsoPage,
    allowRoles: ALL_VISITORS,
  },
  {
    path: '/account/my-profile',
    exact: true,
    component: routesMap.MyProfilePage,
    allowRoles: ConnectedACLRules.MyProfilePage,
  },
  {
    path: '/account/edit/my-profile',
    exact: true,
    component: routesMap.EditMyProfilePage,
    allowRoles: ConnectedACLRules.MyProfilePage,
  },
  {
    path: '/logs/motortrade',
    exact: true,
    component: routesMap.MotorTradePage,
    allowRoles: ConnectedACLRules.CountriesPage,
  },
  {
    path: '/reports/stock-traffic/:portalId',
    exact: true,
    component: routesMap.StockTrafficPortalPage,
    allowRoles: ConnectedACLRules.ReportsPage,
  },
  {
    path: '/reports/stock-traffic',
    exact: true,
    component: routesMap.StockTrafficPage,
    allowRoles: ConnectedACLRules.ReportsPage,
  },
  {
    path: '/countries',
    exact: true,
    component: routesMap.CountriesPage,
    allowRoles: ConnectedACLRules.CountriesPage,
  },
  {
    path: '/countries/details/:countryId',
    exact: true,
    component: routesMap.CountryViewPage,
    allowRoles: ConnectedACLRules.CountriesPage,
  },
  {
    path: '/countries/create',
    exact: true,
    component: routesMap.CountryAddPage,
    allowRoles: ConnectedACLRules.CountriesPage,
  },
  {
    path: '/countries/edit/:countryId',
    exact: true,
    component: routesMap.CountryEditPage,
    allowRoles: ConnectedACLRules.CountriesPage,
  },
  {
    path: '/point-of-sales',
    exact: true,
    component: routesMap.PosPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/point-of-sales/create',
    exact: true,
    component: routesMap.PosAddPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/point-of-sales/details/:posId',
    exact: true,
    component: routesMap.PosDetailsPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/point-of-sales/edit/:posId',
    exact: true,
    component: routesMap.PosEditPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/point-of-sales/edit/:posId/portals/:portalId',
    exact: true,
    component: routesMap.PosEditPortalImagesPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/point-of-sales/edit/:posId/portals-player/:portalId',
    exact: true,
    component: routesMap.PosEditPortalPlayerImagesPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/point-of-sales/edit/:posId/portals-logo/:portalId',
    exact: true,
    component: routesMap.PosEditPortalLogoPage,
    allowRoles: ConnectedACLRules.PosPage,
  },
  {
    path: '/users',
    exact: true,
    component: routesMap.UsersPage,
    allowRoles: ConnectedACLRules.UsersPage,
  },
  {
    path: '/users/details/:userId',
    exact: true,
    component: routesMap.UserViewPage,
    allowRoles: ConnectedACLRules.UsersPage,
  },
  {
    path: '/users/edit/:userId',
    exact: true,
    component: routesMap.UserEditPage,
    allowRoles: ConnectedACLRules.UsersPage,
  },
  {
    path: '/',
    exact: true,
    component: routesMap.OffersPage,
    allowRoles: ConnectedACLRules.OffersPage,
  },
  {
    path: '/offers/create',
    exact: true,
    component: routesMap.OfferAddPage,
    allowRoles: ConnectedACLRules.OffersPageAddingOffers,
  },
  {
    path: '/offers/no-pois',
    exact: true,
    component: routesMap.OfferAddWithoutPosPage,
    allowRoles: ConnectedACLRules.OffersPageAddingOffers,
  },
  {
    path: '/offers/file',
    exact: true,
    component: routesMap.OfferAddFromFilePage,
    allowRoles: ConnectedACLRules.OffersPageAddingOffersFromFile,
  },
  {
    path: '/offers/matrix-file',
    exact: true,
    component: routesMap.OfferAddMatrixFromFilePage,
    allowRoles: ConnectedACLRules.OffersPageAddingOffers,
  },
  {
    path: '/offers/all-pos',
    exact: true,
    component: routesMap.OffersAllPosPage,
    allowRoles: ConnectedACLRules.OffersSearchAllPosPage,
  },
  {
    path: '/offers/edit/:id',
    component: routesMap.OfferEditPage,
    allowRoles: ConnectedACLRules.OffersPage,
    routes: [
      {
        path: '/offers/edit/:id/equipment',
        exact: true,
        component: routesMap.OfferEditEquipmentPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/finance',
        exact: true,
        component: routesMap.OfferEditFinancePage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/damages',
        exact: true,
        component: routesMap.OfferEditDamagesPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/maintenance',
        exact: true,
        component: routesMap.OfferEditMaintenancePage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/maintenance/create',
        exact: true,
        component: routesMap.OfferEditMaintenanceAddItemPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/maintenance/edit/:eventId',
        exact: true,
        component: routesMap.OfferEditMaintenanceEditItemPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/upload-photos',
        exact: true,
        component: routesMap.OfferEditUploadPhotosPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/assign-photos',
        exact: true,
        component: routesMap.OfferEditAssignPhotosPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/portals',
        exact: true,
        component: routesMap.OfferEditPortalsPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/hotspots',
        exact: true,
        component: routesMap.NoMatchPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id/exports',
        exact: true,
        component: routesMap.OfferEditExportPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        path: '/offers/edit/:id',
        component: routesMap.OfferEditVehicleDataPage,
        allowRoles: ConnectedACLRules.OffersPage,
      },
    ],
  },
  {
    path: '/reset-prices',
    exact: true,
    component: routesMap.OfferResetPricesPage,
    allowRoles: ConnectedACLRules.ResetPricesPage,
  },
  {
    path: '/publish-offers-bulk',
    exact: true,
    component: routesMap.OfferPublishOffersBulkPage,
    allowRoles: ConnectedACLRules.PublishOffersBulkPage,
  },
  {
    path: '/manage-photos',
    exact: true,
    component: routesMap.NoMatchPage,
    allowRoles: ConnectedACLRules.ManagePortalPage,
  },
  {
    path: '/manage-files',
    exact: true,
    component: routesMap.NoMatchPage,
    allowRoles: ConnectedACLRules.ManagePortalPage,
  },
  {
    path: '/manage-seo',
    exact: true,
    component: routesMap.SeoPage,
    allowRoles: ConnectedACLRules.SEOPage,
  },
  {
    path: '/manage-seo/create',
    exact: true,
    component: routesMap.SeoAddPage,
    allowRoles: ConnectedACLRules.SEOPage,
  },
  {
    path: '/manage-seo/edit/:portalId/:tagId',
    exact: true,
    component: routesMap.SeoEditPage,
    allowRoles: ConnectedACLRules.SEOPage,
  },
  {
    path: '/manage-seo/details/:portalId/:tagId',
    exact: true,
    component: routesMap.SeoDetailsPage,
    allowRoles: ConnectedACLRules.SEOPage,
  },
  {
    path: '/transactions',
    exact: true,
    component: routesMap.TransactionsPage,
    allowRoles: ConnectedACLRules.TransactionsPage,
  },
  {
    path: '/translations',
    exact: true,
    component: routesMap.TranslationsPage,
    allowRoles: ConnectedACLRules.TranslationsPage,
  },
  {
    path: '/labels',
    exact: true,
    component: routesMap.LabelsPage,
    allowRoles: ConnectedACLRules.LabelsPage,
  },
  {
    path: '/labels/create',
    exact: true,
    component: routesMap.LabelsAddPage,
    allowRoles: ConnectedACLRules.LabelsPage,
  },
  {
    path: '/labels/edit/:id',
    exact: true,
    component: routesMap.LabelsEditPage,
    allowRoles: ConnectedACLRules.LabelsPage,
  },
  {
    path: '/partner-companies',
    exact: true,
    component: routesMap.PartnerCompaniesPage,
    allowRoles: ConnectedACLRules.PartnerCompaniesPage,
  },
  {
    path: '/partner-companies/create',
    exact: true,
    component: routesMap.PartnerCompaniesAddPage,
    allowRoles: ConnectedACLRules.PartnerCompaniesPage,
  },
  {
    path: '/partner-companies/edit/:id',
    exact: true,
    component: routesMap.PartnerCompaniesEditPage,
    allowRoles: ConnectedACLRules.PartnerCompaniesPage,
  },
  {
    path: '/vehicle-groups',
    exact: true,
    component: routesMap.VehicleGroupsPage,
    allowRoles: ConnectedACLRules.VehicleGroupsPage,
  },
  {
    path: '/vehicle-groups/create',
    exact: true,
    component: routesMap.VehicleGroupsAddPage,
    allowRoles: ConnectedACLRules.VehicleGroupsPage,
  },
  {
    path: '/vehicle-groups/edit/:id',
    exact: true,
    component: routesMap.VehicleGroupsEditPage,
    allowRoles: ConnectedACLRules.VehicleGroupsPage,
  },
  {
    path: '/promotion-parameters',
    exact: true,
    component: routesMap.PromotionParametersPage,
    allowRoles: ConnectedACLRules.PromotionParametersPage,
  },
  {
    path: '/promotion-parameters/create',
    exact: true,
    component: routesMap.PromotionParametersAddPage,
    allowRoles: ConnectedACLRules.PromotionParametersPage,
  },
  {
    path: '/promotion-parameters/edit/:id',
    exact: true,
    component: routesMap.PromotionParametersEditPage,
    allowRoles: ConnectedACLRules.PromotionParametersPage,
  },
  {
    path: '/upload-photos',
    exact: true,
    component: routesMap.UploadPhotosPage,
    allowRoles: ConnectedACLRules.UploadPhotos,
  },
  {
    path: '/no-access',
    component: routesMap.NoAccessPage,
    allowRoles: ALL_VISITORS,
  },
  {
    path: '/offer-deleted',
    component: routesMap.OfferDeletedPage,
    allowRoles: ALL_VISITORS,
  },
  {
    component: routesMap.NoMatchPage,
    allowRoles: ALL_VISITORS,
  },
];

const mapACLRouting = (route) => {
  const newRoute = { ...route };

  newRoute.component = withACL(newRoute.component);

  if (isArray(newRoute.routes)) {
    newRoute.routes = map(newRoute.routes, mapACLRouting);
  }

  return newRoute;
};

export const aclRouting = map(routing, mapACLRouting);

const mainMenu = [
  {
    name: 'main_nav_home_page',
    icon: '+',
    allowRoles: ConnectedACLRules.OffersPage,
    url: '/',
  },
  {
    name: 'main_nav_configuration',
    allowRoles: [...ConnectedACLRules.ConfigurationPage, CountryManager],
    items: [
      {
        name: 'main_nav_my_profile',
        url: '/account/my-profile',
        allowRoles: ConnectedACLRules.ConfigurationPage,
      },
      {
        name: 'main_nav_countries',
        url: '/countries',
        allowRoles: ConnectedACLRules.ConfigurationPage,
      },
      {
        name: 'main_nav_point_of_sales',
        url: '/point-of-sales',
        allowRoles: [...ConnectedACLRules.ConfigurationPage, CountryManager],
      },
      {
        name: 'main_nav_users',
        url: '/users',
        allowRoles: ConnectedACLRules.ConfigurationPage,
      },
      {
        name: 'main_nav_labels',
        url: '/labels',
        allowRoles: ConnectedACLRules.LabelsPage,
      },
    ],
  },
  {
    name: 'main_nav_offers',
    allowRoles: ConnectedACLRules.OffersPage,
    items: [
      {
        name: 'main_nav_add_new',
        url: '/offers/create',
        allowRoles: ConnectedACLRules.OffersPageAddingOffers,
      },
      {
        name: 'main_nav_no_pois',
        url: '/offers/no-pois',
        allowRoles: ConnectedACLRules.OffersPageAddingOffers,
      },
      {
        name: 'main_nav_add_new_file',
        url: '/offers/file',
        allowRoles: ConnectedACLRules.OffersPageAddingOffersFromFile,
      },
      {
        name: 'main_nav_add_matrix_file',
        url: '/offers/matrix-file',
        allowRoles: ConnectedACLRules.OffersPageAddingOffers,
      },
      {
        name: 'main_nav_listing',
        url: '/',
        allowRoles: ConnectedACLRules.OffersPage,
      },
      {
        name: 'main_nav_search_all_pos',
        url: '/offers/all-pos',
        allowRoles: ConnectedACLRules.OffersSearchAllPosPage,
      },
      {
        name: 'main_nav_reset_prices',
        url: '/reset-prices',
        allowRoles: ConnectedACLRules.ResetPricesPage,
      },
      {
        name: 'main_nav_publish_offers_bulk',
        url: '/publish-offers-bulk',
        allowRoles: ConnectedACLRules.PublishOffersBulkPage,
      },
    ],
  },
  {
    name: 'main_nav_manage_portal',
    allowRoles: []
      .concat(ConnectedACLRules.SEOPage)
      .concat(ConnectedACLRules.ManagePortalPage),
    items: [
      {
        name: 'main_nav_manage_seo',
        url: '/manage-seo',
        allowRoles: ConnectedACLRules.SEOPage,
      },
      {
        name: 'main_nav_manage_upload_photos',
        url: '/upload-photos',
        allowRoles: ConnectedACLRules.UploadPhotos,
      },
    ],
  },
  {
    name: 'main_nav_checkout',
    allowRoles: ConnectedACLRules.TransactionsPage,
    items: [
      {
        name: 'main_nav_transactions',
        url: '/transactions',
        allowRoles: ConnectedACLRules.TransactionsPage,
      },
      {
        name: 'main_nav_translations',
        url: '/translations',
        allowRoles: ConnectedACLRules.TranslationsPage,
      },
    ],
  },
  {
    name: 'main_nav_logs',
    allowRoles: ConnectedACLRules.LogsPage,
    items: [
      {
        name: 'main_nav_logs_motortrade',
        url: '/logs/motortrade',
        allowRoles: ConnectedACLRules.LogsPage,
      },
    ],
  },
  {
    name: 'main_nav_reports',
    allowRoles: ConnectedACLRules.ReportsPage,
    items: [
      {
        name: 'main_nav_reports_stock_traffic',
        url: '/reports/stock-traffic',
        allowRoles: ConnectedACLRules.ReportsPage,
      },
    ],
  },
  {
    name: 'main_nav_search',
    icon: "'",
    allowRoles: ConnectedACLRules.OffersSearchAllPosPage,
    url: '/offers/all-pos',
  },
];

export const getFilteredACLMenu = (currentRoles) => {
  const transformMenu = (result, value) => {
    const newValue = { ...value };

    if (isArray(newValue.items) && newValue.items.length > 0) {
      newValue.items = transform(newValue.items, transformMenu, []);
    }

    if (
      some(currentRoles, (currentRole) =>
        includes(newValue.allowRoles, currentRole)
      ) ||
      (isArray(newValue.items) && newValue.items.length > 0)
    ) {
      result.push(newValue);
    }
  };

  return transform(mainMenu, transformMenu, []);
};
